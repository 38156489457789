<template>
  <div class="column is-5 dash">
<!--    <div class="divider"></div>-->
    <div class="gradient-3">
      <div class="gradient-2">
        <div class="gradient-1">

        </div>
      </div>
    </div>
    <div class="bg-blur"></div>
    <section class="move-top">
      <div class="is-flex is-align-items-center recharge">
        <img :src="logo" class="ball"/>
      </div>
      <div class="slogan">
        {{ $t('login_slogan') }}
      </div>
      <div class="forget-wallet is-flex is-align-items-center is-justify-content-center">
        <span class="agent-request">{{ $t('agent_request') }}</span>
      </div>
    </section>

  </div>
</template>

<script>
import logo from '../../assets/remisario/fullcolor/isotipo.png'

export default {
  name: 'leftPanelSecurity',
  data() {
    return {
      logo,
    }
  },
  methods: {},
  props: ['register']

}
</script>

<style scoped lang="scss">
.column {
  padding-right: 0 !important;
}

.divider {
  background: #fdc4d1;
  height: 20px;
}

.bg-blur {
  //background: #AFDCE0;
  position: relative;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 150vh;
  z-index: 2;
  mix-blend-mode: multiply;
}

.move-top {
  position: absolute;
  top: 0;
  z-index: 9999;
}

.dash {
  //background-image: url("../../assets/img/portada.png");
  background-size: contain;
  background-repeat: no-repeat;
  height: 102vh;
  background-position: center;
}

.recharge {
  margin-top: 131px;
  margin-left: 82px;
}

.recharge-me {
  margin-left: 16px;
  font-size: 32px;
  position: relative;
  z-index: 99999;
  color: #FFFFFF !important;

}

.slogan {
  width: 545px;
  height: 95px;
  margin-left: 100px;
  margin-top: 140px;
  font-style: normal;
  font-weight: 300;
  font-size: 30px;
  line-height: 48px;
  letter-spacing: 0.5px;
  color: #28BCE8;
  position: relative;
  z-index: 9999
}

.forget-wallet {
  cursor: pointer;
  margin-left: 82px;
  width: 282px;
  height: 45px;
  border: 2px solid #28BCE8;
  box-sizing: border-box;
  border-radius: 20px;
  margin-top: 50px;

  .agent-request {
    font-style: normal;
    font-weight: 300;
    font-size: 23px;
    line-height: 30px;
    letter-spacing: 0.5px;
    color: #28BCE8;
  }

}

.overlay {
  background: rgb(15, 37, 224);
  background: linear-gradient(0deg, rgba(15, 37, 224, 0.9023984593837535) 100%, rgba(15, 44, 224, 0.6867121848739496) 100%);
  mix-blend-mode: multiply;
}

/* (1366x768) WXGA Display */
@media screen and (max-width: 1366px) {
  /* insert styles here */

  .recharge {
    margin-top: 80px;
    font-size: 24px;
    margin-left: 0;
  }
  .recharge-me {

    .register {
      font-size: 24px;
    }
  }
  .slogan {
    width: 300px;
    height: 30px;
    margin-left: 40px;
    margin-top: 130px;
    font-size: 16px;
    line-height: 30px;
  }
  .forget-wallet {
    margin-left: 40px;
    width: 215px;
    height: 40px;
    margin-top: 0;

    .agent-request {
      font-size: 17px;
    }

  }

  .ball {
    margin-left: 40px !important;
    width: 24px;
    height: 24px;

    &.register {
      background-color: #FFFFFF;
    }
  }
  .gradient-1 {
    height: 1rem;
    width: 1rem;
  }
  .gradient-2 {
    height: 7rem;
    width: 7rem;
  }
  .gradient-3 {
    height: 11rem;
    width: 11rem;
  }
}
</style>
