<template>
  <div class="is-flex columns content-page">
    <left-panel-security/>
    <div class="column is-7">
      <div class="centered is-flex-direction-column py-6">
        <div dark-color bold class="login-main-text">{{ $t('reset_password') }}</div>
        <div class="login-help-text font-size-15">{{ $t('change_password_help') }}</div>
        <div class="form-login centered is-flex-direction-column">
          <b-field>
            <b-input :placeholder="$t('email')"
                     type="email"
                     required
                     :validation-message="$t('invalid_email')"
                     v-model="reset.email"
                     icon="account"
            >
            </b-input>
          </b-field>
          <b-field class="mt-2">
            <b-input :placeholder="$t('old_password')"
                     type="password"
                     v-model="reset.password"
                     icon="lock-outline"
                     required
                     :validation-message="$t('password_required')"
                     password-reveal
                     icon-clickable>
            </b-input>
          </b-field>

          <b-field class="mt-2">
            <b-input :placeholder="$t('code')"
                     type="number"
                     v-model="reset.code"
                     icon="lock-outline"
                     required
                     :validation-message="$t('code_required')"
            >
            </b-input>
          </b-field>

          <div class="is-flex is-flex-direction-column">
            <b-button @click="resetPassword" class="btn btn-insert" type="is-primary">{{ $t('send') }}</b-button>
            <b-button @click="goBack" class="btn btn-back">
              {{ $t('go_back') }}
            </b-button>
          </div>

        </div>

      </div>
    </div>
  </div>

</template>

<script>
import {mapMutations, mapActions} from "vuex";
import LeftPanelSecurity from "@/components/structure/leftPanelSecurity";

export default {
  name: "loginComponent",
  components: {LeftPanelSecurity},
  data() {
    return {
      reset: {
        email: null,
        password: null,
        code: null
      }
    }
  },
  methods: {
    ...mapMutations({
      setDash: 'SET_IS_DASH',
      setLoginPage: 'SET_IS_LOGIN'
    }),
    ...mapActions({
      doReset: 'RESET_PASSWORD',
      goBack: 'GO_BACK',
      goTo: 'GO_TO'
    }),
    resetPassword() {
      this.doReset(this.reset)
          .then(() => {
            this.goTo('login')
          })
    },
  },
  created() {
    this.setDash(false)
    this.setLoginPage(true)
  }
}
</script>

<style scoped lang="scss">


/* (1366x768) WXGA Display */
@media screen and (max-width: 1366px) {
  /* insert styles here */
  .login-main-text {
    font-size: 40px;
    margin-top: 80px;
  }
  .login-help-text {
    font-size: 12px;
  }
}

</style>